import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { LocaleContext } from "../../components/Layout/Layout";
import { emailRegex, PAGE } from "../../helpers/constants";
import { scrollToTop, trackUser } from "../../helpers/helpers";
import useTranslations from "../../hoc/useTranslations";
import clsx from "clsx";
import Input from "../../components/Form/Input";
import Button from "../../components/Button/Button";
import { routes } from "../../routes";
import Loader from "../../components/Loader/Loader";
import Heading from "../../components/Heading/Heading";
import Description from "../../components/Description/Description";

import SuccessSVG from "../../assets/icons/success.svg";
import ReportSVG from "../../assets/icons/report_form.svg";

import "./CCReport2020.scss";
import SEO from "../../components/seo";

const CCReport2020 = () => {
	const { locale } = useContext(LocaleContext);

	useEffect(() => {
		scrollToTop();
	}, []);

	const [values, setValues] = useReducer((state, newState) => ({ ...state, ...newState }), {
		name: "",
		surname: "",
		company: "",
		email: "",
		checkbox: false,
	});

	const [errors, setErrors] = useReducer((state, newState) => ({ ...state, ...newState }), {
		email: false,
		checkbox: false,
	});

	const [emailSent, setEmailSent] = useState(false);
	const [isSending, setIsSending] = useState(false);

	const handleInputChange = e => {
		const { value } = e.target;
		const { name: type } = e.target;
		const { checked } = e.target;

		switch (type) {
			case "email":
				setErrors({ [type]: !value.match(emailRegex) });
				return setValues({ [type]: value });
			case "checkbox":
				setErrors({ [type]: !checked });
				return setValues({ [type]: checked });
			default:
				return setValues({ [type]: value });
		}
	};

	const validateField = e => {
		const { value } = e.target;
		const { name: type } = e.target;
		const { checked } = e.target;

		switch (type) {
			case "email":
				return setErrors({ [type]: !value.match(emailRegex) });
			case "checkbox":
				return setErrors({ [type]: !checked });
			default:
				return;
		}
	};

	const validateForm = () => {
		let result = true;

		if (!values.email.match(emailRegex)) {
			setErrors({ email: true });
			result = false;
		}

		if (!values.checkbox) {
			setErrors({ checkbox: true });
			result = false;
		}

		return result;
	};

	const handleBlur = e => {
		validateField(e);
	};

	const submitForm = async e => {
		e.preventDefault();
		if (!validateForm()) return;

		setIsSending(true);

		try {
			await axios({
				method: "post",
				url: process.env.GATSBY_MAILCHIMP_REPORT_URL,
				params: {
					EMAIL: values.email,
					NAME: values.name,
					SURNAME: values.surname,
					COMPANY: values.company,
				},
				data: {},
				headers: {
					"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
				},
			});
		} catch (err) {
			throw new Error(
				err &&
					`User just tried to get Botwise report:
					user email: ${values.email}
					${values.name && `user name: ${values.name}`}
					${values.surname && `user surname: ${values.surname}`}
					${values.company && `user company: ${values.company}`}
					error: ${JSON.stringify(err)}`,
			);
		} finally {
			setIsSending(false);
			setEmailSent(true);

			trackUser({
				tagManager: true,
				eventName: "Report2020 form submitted",
			});
		}
	};

	const {
		form: { rodo, privacy_policy, placeholder_email },
		cc_report_2020: {
			title,
			descriptions,
			form: { name, surname, company, get_report, success_message },
			reportInformations,
		},
	} = useTranslations();

	return (
		<>
			<SEO page={PAGE.REPORT} />
			<section className="section section__cc-report">
				<div className="cc-report__form">
					<div className="cc-report__form--container">
						<Heading type={1}>{title}</Heading>
						{descriptions.map(description => (
							<Description key={description}>{description}</Description>
						))}
						{!emailSent ? (
							<form
								className={clsx(
									"form",
									errors.checkbox && "form__invalid--checkbox",
									errors.email && "form__invalid--email",
								)}
								onSubmit={submitForm}
							>
								<Input
									type="text"
									name="name"
									placeholder={name}
									value={values.name}
									handleBlur={handleBlur}
									handleChange={handleInputChange}
								/>
								<Input
									type="text"
									name="surname"
									placeholder={surname}
									value={values.surname}
									handleBlur={handleBlur}
									handleChange={handleInputChange}
								/>
								<Input
									type="email"
									name="email"
									placeholder={placeholder_email + "*"}
									value={values.email}
									handleBlur={handleBlur}
									handleChange={handleInputChange}
								/>
								<Input
									type="text"
									name="company"
									placeholder={company}
									value={values.company}
									handleBlur={handleBlur}
									handleChange={handleInputChange}
								/>
								<Button
									type="submit"
									onClick={submitForm}
									disabled={errors.email || errors.checkbox}
								>
									{get_report}
								</Button>
								<Input
									type="checkbox"
									name="checkbox"
									placeholder={rodo}
									to={routes[locale].privacyPolicy}
									linkValue={privacy_policy}
									value={values.checkbox}
									handleBlur={handleBlur}
									handleChange={handleInputChange}
								/>
								{isSending && <Loader />}
							</form>
						) : (
							<p className="email-sent">
								<SuccessSVG className="email-sent__icon" />
								{success_message}
							</p>
						)}
					</div>
					<ReportSVG className="cc-report__form--image" />
				</div>
				<section className="cc-report__informations">
					{reportInformations.map(({ list_title, list_items }) => (
						<article key={list_title} className="cc-report__informations--container">
							<Heading type={2}>{list_title}</Heading>
							<ul className="cc-report__informations--list">
								{list_items.map(item => (
									<li key={item} className="cc-report__informations--item">
										{item}
									</li>
								))}
							</ul>
						</article>
					))}
				</section>
			</section>
		</>
	);
};

export default CCReport2020;
