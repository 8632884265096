import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import "./Form.scss";
import clsx from "clsx";

const Input = ({ placeholder, value, handleBlur, handleChange, type, to, linkValue, name }) => (
	<label className={clsx("form__label", type === "checkbox" && "form__label--checkbox")}>
		<input
			className="form__input"
			onBlur={handleBlur}
			onChange={handleChange}
			value={value}
			type={type}
			name={name}
		/>
		{linkValue ? (
			<span className="form__label--text">
				{placeholder}{" "}
				<Link className="link form__input--link" to={to} title={linkValue}>
					{linkValue}
				</Link>
			</span>
		) : (
			<span className="form__label--text form__label--text-floating">{placeholder}</span>
		)}
	</label>
);

Input.propTypes = {
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.bool.isRequired]).isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	to: PropTypes.string,
	linkValue: PropTypes.string,
};

Input.defaultProps = {
	to: null,
	linkValue: null,
};

export default Input;
