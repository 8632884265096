import React from "react";
import Layout from "../components/Layout/Layout";
import CCReport2020 from "../containers/CCReport2020/CCReport2020";

const raport2020 = ({ pageContext }) => (
	<Layout pageContext={pageContext}>
		<CCReport2020 />
	</Layout>
);

export default raport2020;
